import { combineReducers } from 'redux';
import loginReducer from './reducers/loginReducer';
import consentReducer from './reducers/consentReducer';
import sendPasswordResetEmailReducer from './reducers/sendPasswordResetEmailReducer';
import verifyOtpReducer from './reducers/verifyOtpReducer';
import resetPasswordReducer from './reducers/resetPasswordReducer';
import addUserReducer from './reducers/addUserReducer';
import editUserReducer from './reducers/editUserReducer';
import deleteUserReducer from './reducers/deleteUserReducer';
import getUsersReducer from './reducers/getUsersReducer';
import getUserByIdReducer from './reducers/getUserByIdReducer';
import createRoleReducer from './reducers/createRoleReducer';
import editRoleReducer from './reducers/editRoleReducer';
import deleteRoleReducer from './reducers/deleteRoleReducer';
import getRolesReducer from './reducers/getRolesReducer';
import getDefaultRolesReducer from './reducers/getDefaultRolesReducer';
import getRolesDropdownReducer from './reducers/getRolesDropdownReducer';
import getRoleByIdReducer from './reducers/getRoleByIdReducer';
import getModulesReducer from './reducers/getModulesReducer';
import updateProfileReducer from './reducers/updateProfileReducer';
import analyseCoverageReducer from './reducers/analyseCoverageReducer';
import reviseCoverageReducer from './reducers/reviseCoverageReducer';
import sendFeedbackReducer from './reducers/sendFeedbackReducer';
import addCaseReducer from './reducers/addCaseReducer';
import editCaseReducer from './reducers/editCaseReducer';
import getCasesReducer from './reducers/getCasesReducer';
import getCaseByIdReducer from './reducers/getCaseByIdReducer';
import deleteCaseReducer from './reducers/deleteCaseReducer';
import getUserDropdownReducer from './reducers/getUserDropdownReducer';
import getCaseCountsReducer from './reducers/getCaseCountsReducer';
import getStateDropdownReducer from './reducers/getStateDropdownReducer';
import getCaseChartDetailsReducer from './reducers/getCaseChartDetailsReducer';
import logoutReducer from './reducers/logoutReducer';
import changePasswordReducer from './reducers/changePasswordReducer';
import getAnalysisReducer from './reducers/getAnalysisReducer';
import getTotalCaseAmountReducer from './reducers/getTotalCaseAmountReducer';
import getFeedbacksReducer from './reducers/getFeedbacksReducer';
import getFeedbackByIdReducer from './reducers/getFeedbackByIdReducer';
import editFeedbackReducer from './reducers/editFeedbackReducer';
import exportFeedbacksReducer from './reducers/exportFeedbacksReducer';
import getAppealLetterReducer from './reducers/getAppealLetterReducer';
import saveAppealLetterReducer from './reducers/saveAppealLetterReducer';
import getHospitalDropdownReducer from './reducers/getHospitalDropdownReducer';
import generateAppealLetterReducer from './reducers/generateAppealLetterReducer';
import getInsuranceDropdownReducer from './reducers/getInsuranceDropdownReducer';
import getInsuranceCompanyByIdReducer from './reducers/getInsuranceCompanyByIdReducer';
import getInsuranceCompaniesReducer from './reducers/getInsuranceCompaniesReducer';
import editInsuranceCompanyReducer from './reducers/editInsuranceCompanyReducer';
import deleteInsuranceCompanyReducer from './reducers/deleteInsuranceCompanyReducer';
import addInsuranceCompanyReducer from './reducers/addInsuranceCompanyReducer';
import getConfigurationOptionsReducer from './reducers/getConfigurationOptionsReducer';
import getHospitalsReducer from './reducers/getHospitalsReducer';
import getHospitalByIdReducer from './reducers/getHospitalByIdReducer';
import addHospitalReducer from './reducers/addHospitalReducer';
import editHospitalReducer from './reducers/editHospitalReducer';
import deleteHospitalReducer from './reducers/deleteHospitalReducer';
import getStatesListReducer from './reducers/getStatesListReducer';
import getConfigurationByIdReducer from './reducers/getConfigurationByIdReducer';
import getConfigurationsReducer from './reducers/getConfigurationsReducer';
import addConfigurationReducer from './reducers/addConfigurationReducer';
import editConfigurationReducer from './reducers/editConfigurationReducer';
import deleteConfigurationReducer from './reducers/deleteConfigurationReducer';
import addRegionReducer from './reducers/addRegionReducer';
import editRegionReducer from './reducers/editRegionReducer';
import deleteRegionReducer from './reducers/deleteRegionReducer';
import getRegionByIdReducer from './reducers/getRegionByIdReducer';
import getRegionsReducer from './reducers/getRegionsReducer';
import getHospitalsBasedOnRegionReducer from './reducers/getHospitalsBasedOnRegionReducer';
import getModuleByIdReducer from './reducers/getModuleByIdReducer';
import getModulesListReducer from './reducers/getModulesListReducer';
import deleteModuleReducer from './reducers/deleteModuleReducer';
import editModuleReducer from './reducers/editModuleReducer';
import addModuleReducer from './reducers/addModuleReducer';
import getOrganizationsReducer from './reducers/getOrganizationsReducer';
import getOrganizationByIdReducer from './reducers/getOrganizationByIdReducer';
import addOrganizationReducer from './reducers/addOrganizationReducer';
import editOrganizationReducer from './reducers/editOrganizationReducer';
import deleteOrganizationReducer from './reducers/deleteOrganizationReducer';
import addOrganizationRoleReducer from './reducers/addOrganizationRoleReducer';
import editOrganizationRoleReducer from './reducers/editOrganizationRoleReducer';
import deleteOrganizationRoleReducer from './reducers/deleteOrganizationRoleReducer';
import getOrganizationRoleByIdReducer from './reducers/getOrganizationRoleByIdReducer';
import getCountByFilingAndRevenueReducer from './reducers/getCountByFilingAndRevenueReducer';
import getPayerConfigurationsReducer from './reducers/getPayerConfigurationsReducer';
import addPayerConfigurationReducer from './reducers/addPayerConfigurationReducer';
import editPayerConfigurationReducer from './reducers/editPayerConfigurationReducer';
import getPayerConfigurationByIdReducer from './reducers/getPayerConfigurationByIdReducer';
import checkPayerConfigurationReducer from './reducers/checkPayerConfigurationReducer';
import getInsuranceCompaniesStatisticsReducer from './reducers/getInsuranceCompaniesStatisticsReducer';
import getInsuranceCompaniesFilingStatusCountsReducer from './reducers/getInsuranceCompaniesFilingStatusCountsReducer';
import getInsuranceCompaniesBucketReducer from './reducers/getInsuranceCompaniesBucketReducer';
import getReimbursementsReducer from './reducers/getReimbursementsReducer';
import getCaseAmountBucketReducer from './reducers/getCaseAmountBucketReducer';
import getAnalysisStatusReducer from './reducers/getAnalysisStatusReducer';
import saveReferencesReducer from './reducers/saveReferencesReducer';
import getVerifyApproveAppealLetterUserListReducer from './reducers/getVerifyApproveAppealLetterUserListReducer';
import getNotificationReducer from './reducers/getNotificationReducer';
import getMessagesReducer from './reducers/getMessagesReducer';
import addMessageReducer from './reducers/addMessageReducer';
import getChatUsersReducer from './reducers/getChatUsersReducer';
import getPreCalculatedDataReducer from './reducers/getPreCalculatedDataReducer';
import editPreCalculatedDataReducer from './reducers/editPreCalcultedDataReducer';

const rootReducer = combineReducers({
	login: loginReducer,
	consent: consentReducer,
	sendPasswordResetEmail: sendPasswordResetEmailReducer,
	verifyOtp: verifyOtpReducer,
	resetPassword: resetPasswordReducer,
	addUser: addUserReducer,
	editUser: editUserReducer,
	deleteUser: deleteUserReducer,
	getUsers: getUsersReducer,
	getUserById: getUserByIdReducer,
	createRole: createRoleReducer,
	editRole: editRoleReducer,
	deleteRole: deleteRoleReducer,
	getRoles: getRolesReducer,
	getDefaultRoles: getDefaultRolesReducer,
	getRolesDropdown: getRolesDropdownReducer,
	getRoleById: getRoleByIdReducer,
	getModules: getModulesReducer,
	updateProfile: updateProfileReducer,
	analyseCoverage: analyseCoverageReducer,
	reviseCoverage: reviseCoverageReducer,
	sendFeedback: sendFeedbackReducer,
	addCase: addCaseReducer,
	editCase: editCaseReducer,
	getCases: getCasesReducer,
	exportFeedbacks: exportFeedbacksReducer,
	getCaseById: getCaseByIdReducer,
	deleteCase: deleteCaseReducer,
	getUserDropdown: getUserDropdownReducer,
	getCaseCounts: getCaseCountsReducer,
	logout: logoutReducer,
	changePassword: changePasswordReducer,
	getAnalysis: getAnalysisReducer,
	getStateDropdown: getStateDropdownReducer,
	getStatesList: getStatesListReducer,
	getCaseChartDetails: getCaseChartDetailsReducer,
	getTotalCaseAmount: getTotalCaseAmountReducer,
	getFeedbacks: getFeedbacksReducer,
	getFeedbackById: getFeedbackByIdReducer,
	editFeedback: editFeedbackReducer,
	getAppealLetter: getAppealLetterReducer,
	saveAppealLetter: saveAppealLetterReducer,
	getHospitalDropdown: getHospitalDropdownReducer,
	generateAppealLetter: generateAppealLetterReducer,
	getInsuranceDropdown: getInsuranceDropdownReducer,
	getInsuranceCompanyById: getInsuranceCompanyByIdReducer,
	getInsuranceCompanies: getInsuranceCompaniesReducer,
	editInsuranceCompany: editInsuranceCompanyReducer,
	deleteInsuranceCompany: deleteInsuranceCompanyReducer,
	addInsuranceCompany: addInsuranceCompanyReducer,
	getConfigurationOptions: getConfigurationOptionsReducer,
	getHospitals: getHospitalsReducer,
	getHospitalById: getHospitalByIdReducer,
	addHospital: addHospitalReducer,
	editHospital: editHospitalReducer,
	deleteHospital: deleteHospitalReducer,
	getConfigurationById: getConfigurationByIdReducer,
	getConfigurations: getConfigurationsReducer,
	addConfiguration: addConfigurationReducer,
	editConfiguration: editConfigurationReducer,
	deleteConfiguration: deleteConfigurationReducer,
	addRegion: addRegionReducer,
	editRegion: editRegionReducer,
	deleteRegion: deleteRegionReducer,
	getRegionById: getRegionByIdReducer,
	getRegions: getRegionsReducer,
	getHospitalsBasedOnRegion: getHospitalsBasedOnRegionReducer,
	getModuleById: getModuleByIdReducer,
	getModulesList: getModulesListReducer,
	deleteModule: deleteModuleReducer,
	editModule: editModuleReducer,
	addModule: addModuleReducer,
	getOrganizations: getOrganizationsReducer,
	getOrganizationById: getOrganizationByIdReducer,
	addOrganization: addOrganizationReducer,
	editOrganization: editOrganizationReducer,
	deleteOrganization: deleteOrganizationReducer,
	addOrganizationRole: addOrganizationRoleReducer,
	editOrganizationRole: editOrganizationRoleReducer,
	deleteOrganizationRole: deleteOrganizationRoleReducer,
	getOrganizationRoleById: getOrganizationRoleByIdReducer,
	getCountByFilingAndRevenue: getCountByFilingAndRevenueReducer,
	getPayerConfigurations: getPayerConfigurationsReducer,
	addPayerConfiguration: addPayerConfigurationReducer,
	editPayerConfiguration: editPayerConfigurationReducer,
	getPayerConfigurationById: getPayerConfigurationByIdReducer,
	checkPayerConfiguration: checkPayerConfigurationReducer,
	getInsuranceCompaniesStatistics: getInsuranceCompaniesStatisticsReducer,
	getInsuranceCompaniesFilingStatusCounts:
		getInsuranceCompaniesFilingStatusCountsReducer,
	getInsuranceCompaniesBucket: getInsuranceCompaniesBucketReducer,
	getReimbursements: getReimbursementsReducer,
	getCaseAmountBucket: getCaseAmountBucketReducer,
	getAnalysisStatus: getAnalysisStatusReducer,
	saveReferences: saveReferencesReducer,
	getNotification: getNotificationReducer,
	getMessages: getMessagesReducer,
	addMessage: addMessageReducer,
	getVerifyApproveAppealLetterUserList:
		getVerifyApproveAppealLetterUserListReducer,
	getChatUsers: getChatUsersReducer,
	getPreCalculatedData: getPreCalculatedDataReducer,
	editPreCalculatedData: editPreCalculatedDataReducer,
});

export default rootReducer;
