import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
	XSpace,
	XDropdown,
	XAvatar,
	XDownOutlined,
	XSpaceNotif,
	Breadcrumb,
	XBreadcrumb,
} from './HeaderStyle';
import { XContainer } from '../../styles/GlobalStyle';
// actions
import { logoutApi, logoutReset } from '../../actions/logoutAction';
import { Col, Row, notification } from 'antd';
import config from '../../config';
import { getUserProfile } from '../../utils/helper';
// Todo Notification Commented for Demo Branch
import NotificationPopover from '../Notification/NotificationsPopover';

const HeaderFile = (props) => {
	const { logout, logoutReset, logoutState } = props;
	const navigate = useNavigate();

	const items = [
		{
			key: '1',
			label: <Link to="/profile">My Profile</Link>,
			icon: (
				<img
					className="img-fluid"
					src="/images/profile-icon.svg"
					alt=""
				/>
			),
		},
		{
			type: 'divider',
		},
		{
			key: '2',
			label: <Link to="/change-password">Reset Password</Link>,
			icon: <img className="img-fluid" src="/images/reset.svg" alt="" />,
		},
		{
			type: 'divider',
		},
		{
			key: '3',
			label: (
				<Link
					onClick={() => {
						console.log('loks');
						logout();
					}}
				>
					Log Out
				</Link>
			),
			icon: <img className="img-fluid" src="/images/logout.svg" alt="" />,
		},
	];

	// use effect
	useEffect(() => {
		if (logoutState.apiState === 'success') {
			localStorage.removeItem('accessToken');
			localStorage.removeItem('username');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('expiresAt');
			localStorage.removeItem('profile');
			localStorage.removeItem('permissions');
			localStorage.removeItem('fcmToken');
			logoutReset();
			navigate('/login');
		} else if (logoutState.apiState === 'error') {
			notification.error({ message: logoutState.message });
			logoutReset();
		}
	}, [logoutState]);

	return (
		<XContainer
			fluid
			className="mb-4 mt-2"
			style={{ display: 'flex', justifyContent: 'space-between' }}
		>
			{props.breadcrumb === 'true' ? (
				<div className="d-block">
					{props.preview === 'true' ? (
						<>
							<label className="headerTagStyle">
								{props.previewId}
							</label>
							<br />
							<XBreadcrumb
								items={[
									{
										title: (
											<Link to="/case-filing-management">
												{props.headerTag}
											</Link>
										),
									},
									{
										title: (
											<Link onClick={() => navigate(-1)}>
												{props.caseId}
											</Link>
										),
									},
									{
										title: <>{props.previewId}</>,
									},
								]}
							/>
						</>
					) : (
						<>
							<label className="headerTagStyle">
								{props.caseId}
							</label>
							<br />
							<XBreadcrumb
								items={[
									{
										title: (
											<Link
												to={
													props.link
														? `${props.link}`
														: '/case-filing-management'
												}
											>
												{props.headerTag}
											</Link>
										),
									},
									{
										title: <>{props.caseId}</>,
									},
								]}
							/>
						</>
					)}
				</div>
			) : (
				<label className="headerTagStyle">{props.headerTag}</label>
			)}
			<Row>
				<Col xs={12} lg={12} xl={12}>
					<XDropdown
						menu={{
							items,
						}}
						placement="bottom"
					>
						<XSpace>
							<XAvatar
								size={30}
								src={
									getUserProfile() === 'undefined'
										? '/images/prifile.svg'
										: `${config.s3_url}${getUserProfile()}`
								}
							/>
							<XDownOutlined />
						</XSpace>
					</XDropdown>
				</Col>
				{/* // Todo Notification Commented for Demo branch */}
				<NotificationPopover />
				{/* <Col
					xs={12}
					lg={12}
					xl={12}
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					 <XSpaceNotif>
						<img src="/images/bell-icon.svg" alt="bell" />{' '}
					</XSpaceNotif> 
				</Col> */}
			</Row>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({
	logoutState: state.logout,
});

const mapDispatchToProps = (dispatch) => ({
	logout: (params) => dispatch(logoutApi(params)),
	logoutReset: () => dispatch(logoutReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderFile);
