import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_PRE_CALCULATED_DATA = 'EDIT_PRE_CALCULATED_DATA';
export const EDIT_PRE_CALCULATED_DATA_SUCCESS =
	'EDIT_PRE_CALCULATED_DATA_SUCCESS';
export const EDIT_PRE_CALCULATED_DATA_FAIL = 'EDIT_PRE_CALCULATED_DATA_FAIL';
export const EDIT_PRE_CALCULATED_DATA_RESET = 'EDIT_PRE_CALCULATED_DATA_RESET';

export const editPreCalculatedData = (params) => {
	return {
		type: EDIT_PRE_CALCULATED_DATA,
		params,
	};
};

export const editPreCalculatedDataSuccess = (response) => {
	return {
		type: EDIT_PRE_CALCULATED_DATA_SUCCESS,
		response,
	};
};

export const editPreCalculatedDataFail = (response) => {
	return {
		type: EDIT_PRE_CALCULATED_DATA_FAIL,
		response,
	};
};

export const editPreCalculatedDataReset = () => {
	return {
		type: EDIT_PRE_CALCULATED_DATA_RESET,
	};
};

export const editPreCalculatedDataApi = (data) => {
	return (dispatch) => {
		dispatch(editPreCalculatedData());
		axios
			.post(
				`${config.api.base_url}/dashboard/recalculated-dashboard-data`,
				data,
				{
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			)
			.then((response) => {
				const editPreCalculatedData = response.data;
				dispatch(editPreCalculatedDataSuccess(editPreCalculatedData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(editPreCalculatedDataFail(errorMsg));
			});
	};
};
